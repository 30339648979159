import Modal from "@/components/shared/modal";
import { useState, Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { LoadingDots, Google } from "@/components/shared/icons";
import Image from "next/image";

type SignInModalProps = {
  showSignInModal: boolean;
  setShowSignInModal: Dispatch<SetStateAction<boolean>>;
  translations: any;
};

const SignInModal = ({ showSignInModal, setShowSignInModal, translations }: SignInModalProps) => {
  const [signInClicked, setSignInClicked] = useState(false);

  // const handleLogin = () => {

  //   // Redirect the user to your backend's Google authentication endpoint
  //   window.location.href = process.env.NEXT_PUBLIC_BACKEND_URL + "/auth/google";
  // };

  const handleLogin = () => {
    const referrer = localStorage.getItem("affiliateId");

    //let redirectUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/google`;
    const stateObject = { domain: process.env.NEXT_PUBLIC_BASE_URL } as any;
    // Append state parameter if referrer exists and is non-empty
    if (referrer && referrer.length > 0) {
      stateObject.referrer = referrer;
      // Convert state str to base64
      // Remove the affiliateId from localStorage
      // localStorage.removeItem("affiliateId");
    }

    const stateValue = encodeURIComponent(
      Buffer.from(JSON.stringify(stateObject)).toString("base64"),
    );
    let redirectUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/google?state=${stateValue}`;
    // const stateObject = {
    //   referrer: referrer,
    // };

    //     // Convert state str to base64
    //     const stateValue = encodeURIComponent(Buffer.from(JSON.stringify(stateObject)).toString('base64'));
    //     // Redirect the user to your backend's Google authentication endpoint
    //     window.location.href = `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/google?state=${stateValue}`;

    // Redirect the user to your backend's Google authentication endpoint
    window.location.href = redirectUrl;
  };

  // console.log(localStorage.getItem("affiliateId")?.length);

  return (
    <Modal showModal={showSignInModal} setShowModal={setShowSignInModal}>
      <div className="w-full overflow-hidden border-0 border-[#3C3C3C] shadow-xl sm:border-[0.5px] md:max-w-md md:rounded-2xl">
        <div className="flex flex-col items-center justify-center space-y-3 border-b border-b-[0.5px] border-[#3C3C3C] bg-[#202124] px-4 py-6 pt-8 text-center md:px-16">
          <a href={process.env.NEXT_PUBLIC_BASE_URL}>
            <Image
              src={"https://cdn6.imgpog.com/logoCircle.webp"}
              alt="Create Porn Logo"
              className="h-10 w-10 rounded-full"
              width={20}
              height={20}
              unoptimized
            />
          </a>
          <h3 className="font-display text-2xl font-bold text-white">
            {translations.signIn}
          </h3>
          <p className="text-sm text-white">{translations.signInDescription}</p>
        </div>

        <div className="flex flex-col space-y-4 bg-[#313338] px-4 py-8 md:px-16">
          <button
            disabled={signInClicked}
            className={`${
              signInClicked
                ? "cursor-not-allowed border-gray-200 bg-gray-100"
                : "border border-gray-200 bg-white text-black hover:bg-gray-50"
            } flex h-10 w-full items-center justify-center space-x-3 rounded-md border text-sm shadow-sm transition-all duration-75 focus:outline-none focus:ring-0`}
            onClick={() => {
              setSignInClicked(true);
              handleLogin();
            }}
          >
            {signInClicked ? (
              <LoadingDots color="#808080" />
            ) : (
              <>
                <Google className="h-5 w-5" />
                <p>{translations.signInButton}</p>
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export function useSignInModal(translations:any) {
  const [showSignInModal, setShowSignInModal] = useState(false);

  const SignInModalCallback = useCallback(() => {
    return (
      <SignInModal showSignInModal={showSignInModal} setShowSignInModal={setShowSignInModal}
      translations={translations} />
    );
  }, [showSignInModal, setShowSignInModal]);

  return useMemo(
    () => ({ setShowSignInModal, SignInModal: SignInModalCallback }),
    [setShowSignInModal, SignInModalCallback],
  );
}
